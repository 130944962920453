import {createContext, useContext} from "react";

export const contexts = [
  "DepartmentContext",
  "PageContext",
  "TypeContext",
  "VariationContext",
  "LayoutNumberContext",
  "TagContext",
  "SubproductContext",
  "ModeContext",
  "WithUploadContext",
  "HeaderTextContext",
  "NameContext",
  "ImageContext",
  "ImageBlogOffsetContext",
  "ImageShareOffsetContext",
  "LanguageContext",
  "TranslateContext",
  "LoadingStateContext",
  "TranslatingStateContext",
  "DateContext",
  "PositionContext",
  "EpisodeContext",
  "SecondLineContext",
  "StageContext",
  "ThirdLineContext",
  "BenchmarkContext",
].reduce((result, name) => {
  const context = (result[name] = createContext(null));
  context.displayName = name;
  return result;
}, {});

export const {ProductContext} = contexts;

export const {DepartmentContext} = contexts;
export const {PageContext} = contexts;
export const {TypeContext} = contexts;

export const {VariationContext} = contexts;
export const {LayoutNumberContext} = contexts;
export const {TagContext} = contexts;
export const {SubproductContext} = contexts;
export const {ModeContext} = contexts;
export const {WithUploadContext} = contexts;

export const {HeaderTextContext} = contexts;

export const {NameContext} = contexts;

export const {ImageContext} = contexts;

export const {ImageBlogOffsetContext} = contexts;

export const {ImageShareOffsetContext} = contexts;

export const {LanguageContext} = contexts;
export const {TranslateContext} = contexts;
export const {TranslatingStateContext} = contexts;
export const {LoadingStateContext} = contexts;
export const {DateContext} = contexts;
export const {PositionContext} = contexts;
export const {EpisodeContext} = contexts;
export const {SecondLineContext} = contexts;
export const {StageContext} = contexts;
export const {ThirdLineContext} = contexts;
export const {BenchmarkContext} = contexts;

export const useType = () => useContext(TypeContext);
export const useDepartment = () => useContext(DepartmentContext);
export const useVariation = () => useContext(VariationContext);
export const useLayoutNumber = () => useContext(LayoutNumberContext);
export const useTag = () => useContext(TagContext);
export const useSubproduct = () => useContext(SubproductContext);
export const useMode = () => useContext(ModeContext);
export const useWithUpload = () => useContext(WithUploadContext);
export const useHeaderText = () => useContext(HeaderTextContext);
export const useName = () => useContext(NameContext);
export const useImage = () => useContext(ImageContext);
export const useImageBlogOffset = () => useContext(ImageBlogOffsetContext);
export const useImageShareOffset = () => useContext(ImageShareOffsetContext);
export const useLanguage = () => useContext(LanguageContext);
export const useTranslate = () => useContext(TranslateContext);
export const useTranslatingStateContext = () => useContext(TranslatingStateContext);
export const useLoadingState = () => useContext(LoadingStateContext);
export const useDate = () => useContext(DateContext);
export const usePosition = () => useContext(PositionContext);
export const useEpisode = () => useContext(EpisodeContext);
export const useSecondLine = () => useContext(SecondLineContext);
export const useStage = () => useContext(StageContext);
export const useThirdLine = () => useContext(ThirdLineContext);
export const useBenchmark = () => useContext(BenchmarkContext);
