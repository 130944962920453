import {Component} from "react";

import { KrakenScreenshoter } from '../hooks/screenshoter';
import { contexts } from "../hooks";

const contextNameToProp = (ctxName) =>
  ctxName[0].toLowerCase() + ctxName.substring(1, ctxName.length - "Context".length);

export class ContextsProvider extends Component {
  constructor(...args) {
    super(...args);

    this.takeSnapshot = this.takeSnapshot.bind(this);
  }

  takeItemSnapshot(ctxName) {
    const prop = contextNameToProp(ctxName);
    const state = this.props[ctxName];

    let data = null;

    if (Array.isArray(state)) data = state[0];
    else if (state) data = state;

    if (data) {
      switch (ctxName) {
        case "ProductContext":
        case "TypeContext":
          data = data.value;
      }
    }

    return [prop, data];
  }

  takeSnapshot() {
    return Object.keys(contexts).reduce((result, ctxName) => {
      const [prop, value] = this.takeItemSnapshot(ctxName);
      if (prop !== "hash") {
        result[prop || ctxName] = value;
      }
      return result;
    }, {});
  }

  render() {
    const { Component, ...props } = this.props;

    return Object.entries(contexts)
      .reduceRight(
        (result, [name, Context]) => <Context.Provider value={props[name]}>{result}</Context.Provider>,

        <KrakenScreenshoter getParams={this.takeSnapshot}>
          <Component/>
        </KrakenScreenshoter>
      );
  }
}
