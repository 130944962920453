import {createContext, useContext, useMemo} from "react";

import Taxonomy from "../components/taxonomy";

export const ProductsContext = createContext(Taxonomy.products);
export const useProducts = () => useContext(ProductsContext);

export const ProductContext = createContext(null);
export const useProduct = () => useContext(ProductContext);

export const findProductById = (productId, products = Taxonomy.products) =>
  products.find((item) => productId === item.value)

export const useProductById = (productId) => {
  const products = useProducts();
  return useMemo(() => products.find((item) => productId === item.value), [ products, productId ]);
}
