import { memo } from "react";

import {ProductChooseRoute} from "../../components/PageProductChoose";

import DataProviders from "../../data/DataProviders";

import {useProduct} from "../../hooks/product";

import Livestream from "./Livestream";
import {useImageBlogOffset, useImageShareOffset} from "../../hooks";



const LivestreamPure = memo(Livestream);

const LivestreamAdditions = () => {
  const [product, setProduct] = useProduct();

  const setBlogOffset = useImageBlogOffset()[1];
  const setShareOffset = useImageShareOffset()[1];

  return (
    <>
    <LivestreamPure
      product={product}
      onProductChange={setProduct}
      onBlogOffset={setBlogOffset}
      onShareOffset={setShareOffset}
    />
      Here
    </>
  );
};

const LivestreamWithData = () => (
  <ProductChooseRoute>
      <DataProviders component={LivestreamAdditions} />
  </ProductChooseRoute>
);

export default LivestreamWithData;
