import {useCallback, useEffect, useMemo, useState} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import Toggle from "@webteam/toggle";
import {Select} from "@webteam/select";
import Button from "@webteam/button";
import Switcher from "@webteam/switcher";
import { useTextStyles } from '@webteam/typography';
import {ThemeProvider} from '@rescui/ui-contexts';
import { List, ListItem } from '@webteam/list';
import {MessageIcon, InfoIcon} from '@webteam/icons';

import {
  useLanguage,
  useVariation,
  useLayoutNumber,
  useTag,
  useTranslate,
  useDepartment,
  useStage,
  useBenchmark
} from "../../hooks";
import {useSceneId} from "../../hooks/sceneId";
import "./toolbox.css";

import {useProduct, useProductById, useProducts} from "../../hooks/product";
import {sendGAEvent} from "../../tools";
import Taxonomy from "../taxonomy";


export const languages = [
  {label: "EN", value: "en", name: "English"},
  {label: "DE", value: "de", name: "German"},
  {label: "FR", value: "fr", name: "French"},
  {label: "ES", value: "es", name: "Spanish"},
  {label: "BR", value: "br", name: "Portuguese"},
  {label: "JP", value: "jp", name: "Japanese"},
  {label: "CH", value: "ch", name: "Chinese"},
  {label: "KR", value: "kr", name: "Korean"},
  {label: "TR", value: "tr", name: "Turkish"},
  {label: "RU", value: "ru", name: "Russian"}
];

const Toolbox = ({
                   children,
                   onProductChange,
                   onSetChange,
                   loading,
                   translating,
                   saving,
                   products,
                   product,
                   set,
                   sets,
                   save,
                   share,
                   variationQty,
                   templateQty,
                   tagQty,
                   isVPN
                 }) => {


  const [department, setDepartment] = useDepartment();
  const url = useLocation().pathname;
  const history = useHistory();
  const [variation, setVariation] = useVariation();
  const [stage, setStage] = useStage();
  const [layoutNumber, setLayoutNumber] = useLayoutNumber();
  const [tag, setTag] = useTag();
  const [language, setLanguage] = useLanguage();
  const [translate_all] = useTranslate();
  const [, setProduct] = useProduct();
  const [hash,] = useSceneId();
  const textCn = useTextStyles();
  const [, setBenchmark] = useBenchmark(false);

  const [isTranslateVisible, setIsTranslateVisible] = useState(false);
  const [copyButtonText, setCopyButtonText] = useState("Copy Link");
  const handleTranslateVisibleChange = () => {
    setIsTranslateVisible(!isTranslateVisible);
  };

  const departments = Taxonomy.channels
    .filter(channel => channel.visible === true)
    .filter(channel => channel.label !== 'Dark');


  const defaultDepartment = useMemo(
    () => departments.find((item) => url.startsWith(item.value)) || departments[0],
    [url]
  );


  useEffect(() => {
    setDepartment(defaultDepartment.value.replace(/^\//, ''));
  }, []);


  useEffect(() => {
    setStage(product?.types?.[0]);
  }, [product, setStage]);

  const productsIdsForChannel = useMemo(() => {
    const currentChannel = Taxonomy.channels.filter(channel => channel.label === defaultDepartment.label);
    return currentChannel?.[0].products;
  }, [history, department]);

  products = products.filter((product) => productsIdsForChannel.includes(product.value));

  products = products?.sort((a, b) => a.label.localeCompare(b.label));


  const onDepartmentChange = useCallback(
    (evt) => {
      history.push(evt.value);
      setDepartment(evt.value.replace(/^\//, ''));
      // setProduct(products[0].value);
    },
    [history, setDepartment]
  );
  useEffect(() => {
    if (defaultDepartment.label === "Internal" && !hash) {
      history.push(defaultDepartment.value + "/" + products[0].value);
    }
  }, [department]);

  useEffect(() => {
    if (defaultDepartment.label === "Dark" && !hash) {
      history.push(defaultDepartment.value + "/" + products[0].value);
    }
  }, [department]);

  const handleSwitchChange = (newValue) => {
    setVariation(newValue);
  };

  const handleTemplateChange = (newValue) => {
    setLayoutNumber(newValue);
  };

  const handleTagChange = (newValue) => {
    setTag(newValue);
  };
  const handleLanguageChange = (newValue) => {
    setLanguage(newValue);
  };


  const handleStageChange = (newValue) => {
    setStage(newValue);
  };


  const productChange = useCallback(
    (evt) => onProductChange(evt.value),
    [onProductChange]
  );
  const variationOptions = useMemo(() => {
    const qty = variationQty || 1;
    return [
      ...[...new Array(qty)].map((_, index) => ({
        label: `${index + 1}`,
        value: index,
      })),
      {
        label: "Custom",
        value: "custom",
      },
    ];
  }, [product, variationQty]);

  const layoutOptions = useMemo(() => {
    const qty = templateQty || 1;
    return [...new Array(qty)].map((_, index) => ({
      label: `${index + 1}`,
      value: index,
    }));
  }, [product, templateQty]);


  const tagOptions = useMemo(() => {
    const qty = tagQty || 1;
    return [...new Array(qty)].map((_, index) => ({
      label: `${index + 1}`,
      value: index,
    }));
  }, [product, tagQty]);


  const handleSaveScene = () => {
    share();
    // console.log(defaultDepartment.label, product.label, stage.label, language )
    sendGAEvent("save_scene", defaultDepartment.label, product.label)
  }


  const saveSceneTrigger = () => {
    handleSaveScene();
    setCopyButtonText("Copied!")
    setTimeout(() => {
      setCopyButtonText("Copy link");
    }, 3000)
  }
  const handleExport = () => {
    save();
    sendGAEvent("export", defaultDepartment.label, product.label)
  }
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const benchmark = searchParams.get('benchmark');
    if (benchmark) {
      setBenchmark(true);
    }
  }, [location]);

  return (
    <ThemeProvider theme={'dark'}>
    <div className="toolbar">
      <div>
        <div className={"channels"}>
        <h5>Channel</h5>
        <Select
          className="control department"
          size="s"
          theme="dark"
          placeholder="Select template"
          onChange={onDepartmentChange}
          options={departments}
          value={defaultDepartment}
        />
        </div>
        <h5>Product</h5>
        <Select
          className="control product"
          isSearchable={true}
          size="s"
          theme="dark"
          onChange={productChange}
          options={products}
          value={defaultDepartment.label === "Internal" ? products[0] : product}
        />

        {product.types && product.types.length > 1 && (defaultDepartment.label === "Social Media & Blog" || defaultDepartment.label === "Internal") && (
          <div>
            <h5>Content type</h5>
            <Select
              key={product.value}
              className="control stage"
              isSearchable={true}
              simpleValue
              size="s"
              theme="dark"
              onChange={handleStageChange}
              options={product.types}
              value={stage}
            />
          </div>
        )}
        <div className="localization">

          <Toggle
            className="visibility-toggle translate-toggle"
            size="xs"
            theme="dark"
            name="translate"
            labelPlacement={"left"}
            onChange={handleTranslateVisibleChange}
            checked={isTranslateVisible}
          >
            Localization
          </Toggle>

          {isTranslateVisible && language && (defaultDepartment.label === "Social Media & Blog" || defaultDepartment.label === "Email" ) && (
            <Switcher
              className="control language"
              size="s"
              theme="dark"
              onChange={handleLanguageChange}
              options={languages}
              value={language}
            />
          )}


          {isTranslateVisible && Boolean(isVPN) && defaultDepartment.label === "Social Media & Blog" &&
            <Button onClick={translate_all} size="s" mode="outline" theme="dark" busy={translating}
                    className="translate_button">
              AI Translate
            </Button>}
        </div>

        {layoutOptions && layoutOptions.length > 1 && (
          <div className={"template"}>
            <h5>Layout</h5>
            <Switcher
              className="control"
              size="xs"
              theme="dark"
              onChange={handleTemplateChange}
              options={layoutOptions}
              value={layoutNumber}
            />
          </div>
        )}

        {tagOptions && tagOptions.length > 1 && (
          <div className={"tags"}>
            <h5>Tags</h5>
            <Switcher
              className="tag_row"
              size="xs"
              theme="dark"
              onChange={handleTagChange}
              options={tagOptions}
              value={tag}
            />
          </div>
        )}


        {variationOptions && variationOptions.length > 1 && (
          <div className={"design variations"}>
            <h5>Background</h5>
            <Switcher
              className="control"
              size="xs"
              theme="dark"
              onChange={handleSwitchChange}
              options={variationOptions}
              value={variation}
            />
          </div>
        )}

        {defaultDepartment.label === "Livestream" && (
          <div className={'parent parent_theme_dark slack_bot_rumpled_jackets'}>
          <a href={"https://slack.com/app_redirect?app=A056WC694N6"} target={'_blank'}  className={textCn('wt-link', {external: true})}>Prepare Photo</a>
          </div>

        )}


        {children}

        {Boolean(share) &&
          <Button onClick={saveSceneTrigger} size="s" mode="outline" theme="dark" busy={saving} className="link_button">
            {copyButtonText}
          </Button>}

        {Boolean(save) && <Button onClick={handleExport} size="s" busy={loading} className="exportbutton">
          Export Images
        </Button>}

        <List mode={'nude'} className={'info'} theme={'dark'} style={{maxWidth: 200}}>
          <a className={'wt-link'} target={'_blank'}
             href='https://youtrack.jetbrains.com/articles/DSGN-A-55/CoverBaker'>
            <ListItem icon={<InfoIcon/>}>Read the manual</ListItem>
          </a>
          <a className={'wt-link'} target={'_blank'} href='https://jetbrains.slack.com/archives/C07JLAY1MFB'>
            <ListItem icon={<MessageIcon/>}>Leave your feedback</ListItem>
          </a>
        </List>

      </div>
    </div>
    </ThemeProvider>
);
};

export default Toolbox;
