import { memo } from "react";

import {ProductChooseRoute} from "../../components/PageProductChoose";

import DataProviders from "../../data/DataProviders";

import {useProduct} from "../../hooks/product";

import Social from "./Social";
import {useImageBlogOffset, useImageShareOffset} from "../../hooks";
// import "../../components/covers/covers.module.css";

const SocialPure = memo(Social);
const SocialAdditions = () => {
  const [product, setProduct] = useProduct();

  const setBlogOffset = useImageBlogOffset()[1];
  const setShareOffset = useImageShareOffset()[1];

  return (
    <SocialPure
      product={product}
      onProductChange={setProduct}
      onBlogOffset={setBlogOffset}
      onShareOffset={setShareOffset}
    />
  );
};

const SocialWithData = () => (
  <ProductChooseRoute>
      <DataProviders component={SocialAdditions} />
  </ProductChooseRoute>
);

export default SocialWithData;
