const Image = (props) => {
  return (
    <img
      src={props.src}
      width={props.width}
      height={props.height}
      onLoad={props.onload}
      className={props.class}
    />
  );
};
export default Image;
