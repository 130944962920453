import {useRouteMatch} from "react-router-dom";
import {useProducts} from "../../hooks/product";
import {useMemo} from "react";

export const useProductChooseRoute = () => {
  const products = useProducts();
  const { path, url } = useRouteMatch();

  const POSSIBLE_PRODUCTS = useMemo(() => products
    .map((item) => item.value)
    .join("|"), [ products ]);

  const PRODUCTS_SELECT_ROUTE = `/:productId(${POSSIBLE_PRODUCTS})/:hash?`;

  return [
    `${path}${PRODUCTS_SELECT_ROUTE}`,
    `${url}/${products[0].value}`
  ];
}
