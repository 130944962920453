
import {HUANGHE} from "./data/constants"


export const API = {
  translate: async (value, target_language) => {
    try {
      const obj = {
        "text": value,
        "target_language": target_language
      }
      const response = await fetch(`${HUANGHE}/tools/translate_text`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(obj)
      });
      const result = await response.json();
      return result.translated_text;
    } catch (err) {
      console.error(err);
    }
  }
}
