import React from "react";
// import Cover from "./Cover";
// import  CoverTwoInputs  from "./CoverTwoInputs";
// import CoverWithUpload from "./CoverWithUpload";
// import styles from "./covers.module.css";
import {useType} from "../../hooks";
import Cover from "./Cover";


const ChooseCover = React.forwardRef((props, styles, ref) => {
  const [type] = useType();
  switch (type.value) {
    case "social":
      return <Cover
        ref={ref}
        styles={styles}
        withUpload={true}
        {...props}
      />
    case "livestream":
      return <Cover
        ref={ref}
        styles={styles}
        withUpload={false}
        {...props}
      />
    case "internal":
      return <Cover
        ref={ref}
        styles={styles}
        withUpload={false}
        {...props}
      />

    case "interviews":
    case "guest-post":
    case "roundup":
    case "news-edu":
      // return <CoverTwoInputs ref={ref} {...props} />;
    case "features":
    case "tips-tricks":
    case "tutorials":
    case "how-tos":
    case "events":
    case "news":
    case "roadmap-custom":
      // return <CoverWithUpload ref={ref} {...props} />;
    default:
      return <Cover
        ref={ref}
        styles={styles}
        withUpload={true}
        {...props}
      />
      // return <Cover ref={ref} {...props} />;
  }
});

export default ChooseCover;
