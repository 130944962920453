import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import {useProductChooseRoute} from "./hooks";
import {ProductChooseProvider} from "./Provider";

export function ProductChooseRoute({ children }) {
  const { path } = useRouteMatch();
  const [ route, defaultUrl ] = useProductChooseRoute();

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={defaultUrl} />
      </Route>
      <Route path={[
        route,
        `${path}/load/:hash`
      ]}>
          <ProductChooseProvider route={route}>
            {children}
          </ProductChooseProvider>
      </Route>
    </Switch>
  );
}

