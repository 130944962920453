import React, {memo, useCallback, useEffect} from "react";
import cx from "classnames";
import ContentEditable from "react-contenteditable";

import {ImageUpload} from "../ImageUpload";

import {
  useDate,
  useEpisode,
  useHeaderText,
  useImage,
  useName,
  useSecondLine,
  useThirdLine,
  usePosition,
  useType, useVariation, useTag, useSubproduct, useMode, useWithUpload
} from "../../hooks";
import {useProduct} from "../../hooks/product";
import { clearFormatting, setFocus } from "../../tools";
import styles from "./covers.module.css";
// import  "./covers.module.css";

const ImageUploadPure = memo(ImageUpload);

function LogoWithLabel({ children }) {
  const [product] = useProduct();
  return <div className={`logo logo-type-${product.value}`}>
    <div className="logo--image"/>
    <div className="logo--product-name">{product.label}</div>
    {children}
  </div>;
}

const Cover = React.forwardRef(({
  children,
  className,
  style,
  withUpload,
  exportable,
  template,
  exportFilename,
  onImageRePosition,
  isFocus,
  ...props
}, ref) => {
  const [headerText, setHeaderText] = useHeaderText();
  const [name, setName] = useName();
  const [date, setDate] = useDate();
  const [secondLine, setSecondLine] = useSecondLine();
  const [thirdLine, setThirdLine] = useThirdLine();
  const [image, setImage] = useImage();
  const [position, setPosition] = usePosition();
  const [episode, setEpisode] = useEpisode();


  const [product] = useProduct();
  const [type] = useType();
  const [variation] = useVariation();
  const [tag] = useTag();
  const [subproduct] = useSubproduct();
  const [mode] = useMode();
  // const [withUpload] = useWithUpload();

  const typeValue = type && type.value;
  const productName = product.value.toLowerCase();

  const classMods = {
    [`template-${template}`]: !!template,
    [`product-${productName}`]: !!productName,
    [`type-${typeValue}`]: typeValue,
    [`set-${variation}`]: variation || variation === 0,
    [`tag-${tag}`]: tag || tag === 0,
    [`sub-${subproduct}`]: subproduct || subproduct === 0,
    [`mode-${mode}`]: mode || mode === 0,
    ['withupload']: !!withUpload
  };

  const onHeaderChange = useCallback(
    ({ target }) => {
      setHeaderText(clearFormatting(target.value));
    },
    [setHeaderText]
  );

  const onNameChange = useCallback(
    ({ target }) => {
      setName(clearFormatting(target.value))
    },
    [setName]
  );

  const onSecondLineChange = useCallback(
    ({ target }) => {
      setSecondLine(clearFormatting(target.value))
    },
    [setSecondLine]
  );

  const onThirdLineChange = useCallback(
    ({ target }) => {
      setThirdLine(clearFormatting(target.value))
    },
    [setThirdLine]
  );

  const onDateChange = useCallback(
    ({ target }) => setDate(clearFormatting(target.value)),
    [setDate]
  );

  const onPositionChange = useCallback(
    ({ target }) => {
      setPosition(clearFormatting(target.value))},
    [setPosition]
  );

  const onEpisodeChange = useCallback(
    ({ target }) => setEpisode(clearFormatting(target.value)),
    [setEpisode]
  );

  useEffect(() => { setFocus(isFocus, ref) }, []);



  const classes = cx(exportable ? "capture-image": "", styles.container, className, classMods);


  let filename = `${productName}`
  if (typeValue) filename += `-${typeValue || ''}`;
  filename += `-${exportFilename || template}`.replace(/\s+/g, '-').toLowerCase();


  return (
      <div className={cx(styles.wrapper, cx(className, 'cover-wrapper'), cx(classMods).split(' ').map(name => `cover-wrapper-${name}`))}>
        <div style={style} className={classes} data-filename={filename}>
          <div className={cx(styles.mainContent, 'mainContent')}>
            <LogoWithLabel>
              <ContentEditable
                className={cx("editable", "episodeInput", styles.episodeInput)}

                html={episode}
                onChange={onEpisodeChange}
              />
            </LogoWithLabel>

            <ContentEditable
              className={cx("editable", "firstLine", styles.firstLine)}

              html={name}
              onChange={onNameChange}
            />
            <ContentEditable
              className={cx("editable", "secondLine", styles.secondLine)}

              html={secondLine}
              onChange={onSecondLineChange}
            />
            <ContentEditable
              className={cx("editable", "thirdLine", styles.thirdLine)}

              html={thirdLine}
              onChange={onThirdLineChange}
            />
            <ContentEditable
              className={cx("editable", "generalInput", styles.generalInput)}

              html={headerText}
              onChange={onHeaderChange}
              innerRef={ref}
            />
            <ContentEditable
              className={cx("editable", "dateInput", styles.dateInput)}
              html={date}
              onChange={onDateChange}
            />
            <ContentEditable
              className={cx("editable", "positionInput", styles.positionInput)}

              html={position}
              onChange={onPositionChange}
            />
          </div>

          <div className="mask"></div>
          {withUpload && <ImageUploadPure
            className="drop-avatar"
            minScale={0.5}
            initialScale={2}
            wheel={{step: 0.0001}}
            image={image}
            setImage={setImage}
            onPositionChange={onImageRePosition}
          />}

          <div className="logo-1" />
          <div className="logo-2" />
          <div className="logo-3" />
        </div>
      </div>
);
});

export default Cover;
