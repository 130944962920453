import {useCallback, useEffect, useMemo} from "react";
import {generatePath, useHistory, useParams} from "react-router-dom";
import {useProductById, ProductContext} from '../../hooks/product';
import {SceneIdContext} from "../../hooks/sceneId";
import {useType} from "../../hooks";

export const ProductChooseProvider = ({ route, children }) => {
  const history = useHistory();
  const { productId, hash } = useParams();

  const product = useProductById(productId);

  const setProduct = useCallback(
    productId => history.push(generatePath(route, { productId })),
    [history, route]
  );

  const setHash = useCallback(
    hash => history.push(generatePath(route, { productId, hash })),
    [history, route, productId]
  );

  return (
    <ProductContext.Provider value={[ product, setProduct ]}>
      <SceneIdContext.Provider value={[ hash, setHash ]}>
        {children}
      </SceneIdContext.Provider>
    </ProductContext.Provider>
  );
}
