const constants =
    { KRAKEN_URL : "https://kraken.labs.jb.gg"
    };
//
// export const PRODUCTS = [
//   "AppCode",
//   "CLion",
//   "DataGrip",
//   "Datalore",
//   "YouTrack"
// ];
//
// export const DEFAULT_PRODUCT = { label : "IntelliJ IDEA", value : "IJ" };
//
//
//


export const KRAKEN_IMAGES = "https://d3r50642m20jlp.cloudfront.net/kraken/tiles";
export const KRAKEN_ICONS = `${KRAKEN_IMAGES}/Logos_Icons_v2`;

export const HUANGHE ='https://huanghe-api.labs.jb.gg';

export const KRAKEN_COLORS = {
  QA: "Aqua",
  // "Arend",
  BDT: "BigDataTools",
  CL: "CLion",
  CWM: "CodeWithMe",
  DG: "DataGrip",
  DS: "DataSpell",
  DL: "Datalore",
  // "Deft",
  // "Dekaf",
  // "Draft",
  EDU: "EduProduct",
  FL: "Fleet",
  // "Gateway",
  GO: "GoLand",
  GR: "Grazie",
  HUB: "Hub",
  // "IdeaVim",
  IJ: "IntellijIDEA",
  // "IntellijIDEACommunity",
  // "IntellijIDEAEdu",
  // "IntellijIDEAUltimate",
  // "IntellijRust",
  // "JBAcademy",
  JB: "JetBrains",
  KT: "Kotlin",
  // "Ktor",
  // "LicenseVault",
  MPS: "MPS",
  // "Marketplace",
  // "Mono",
  PS: "PHPStorm",
  // "PiDay",
  // "Projector",
  PC: "PyCharm",
  // "PyCharmCommunity",
  // "PyCharmDataScience",
  // "PyCharmEdu",
  QT: "QATools",
  QD: "Qodana",
  // "RPlugin",
  RS: "ReSharper",
  RScpp: "ReSharperCpp",
  // "RescUi",
  RD: "Rider",
  // "RiderFlow",
  // "RingUi",
  RM: "RubyMine",
  // "RustRover",
  SP: "Space",
  TC: "TeamCity",
  // "TestIDE",
  TB: "Toolbox",
  WS: "WebStorm",
  // "WritersIDE",
  // "Xodus",
  // "XodusDNQ",
  YT: "YouTrack",
  DC: "dotCover",
  DM: "dotMemory",
  DP: "dotPeek",
  DT: "dotTrace",
  // "dotUltimate",
}


export const fontSizes = [
  {label: "-2", value: 0.8},
  {label: "-1", value: 0.9},
  {label: "standard font size", value: 1},
  {label: "+1", value: 1.1},
  {label: "+2", value: 1.2},
  // {label: "giant", value: 2},
]

export const languages = [
  { label: "EN", value: "en", name: "English"},
  { label: "DE", value: "de", name: "German"},
  { label: "FR", value: "fr", name: "French"},
  { label: "ES", value: "es", name: "Spanish"},
  { label: "BR", value: "br", name: "Portuguese"},
  { label: "JP", value: "jp", name: "Japanese"},
  { label: "CH", value: "ch", name: "Chinese"},
  { label: "KR", value: "kr", name: "Korean"},
  { label: "TR", value: "tr", name: "Turkish"},
  { label: "RU", value: "ru", name: "Russian"}
];

export default constants;

