const getSections = (urls, language, onShareOffset, onBlogOffset) => [
  {
    name: 'first',
    title: 'YouTube First Slide 1920x1080',
    template: 'youtubeFirstSlide',
    url: urls.first,
    filename: `YouTube_First_Slide_1920x1080_${language[0]}`,
    onImageRePosition: onShareOffset
  },
  {
    name: 'leaderboard',
    title: 'Email 650x96@2x',
    template: 'leaderboard',
    url: urls.leaderboard,
    filename: `Email_650x96@2x_${language[0]}`,
    onImageRePosition: onShareOffset
  },

  {
    name: 'half',
    title: 'Email 650x200@2x',
    template: 'half',
    url: urls.half,
    filename: `Email_650x200@2x_${language[0]}`,
    onImageRePosition: onShareOffset
  },
  {
    name: 'content',
    title: 'Email 650x325@2x',
    template: 'content',
    url: urls.content,
    filename: `Email_650x325@2x_${language[0]}`,
    onImageRePosition: onShareOffset
  },
  {
    name: 'longcontent',
    title: 'Email 650x325@2x',
    template: 'longcontent',
    url: urls.longcontent,
    filename: `Long_Email_650x325@2x_${language[0]}`,
    onImageRePosition: onShareOffset
  },
  {
    name: "streamyardbackgroun",
    title: 'StreamYard Background 1920x1080',
    template: 'streamYardBackground',
    url: urls.streamyardbackground,
    filename: `StreamYard_Background_1920x1080_${language[0]}`,
    onImageRePosition: onShareOffset
  },
  {
    name: "streamyardoverlay",
    title: 'StreamYard Overlay 1920x1080',
    template: 'streamYardOverlay',
    url: urls.streamyardoverlay,
    filename: `StreamYard_Overlay_1920x1080_${language[0]}`,
    onImageRePosition: onShareOffset
  },
  {
    name: "thumbnail",
    title: 'YouTube Thumbnail 1280x720',
    template: 'youtubeThumbnail',
    url: urls.thumbnail,
    filename: `YouTube_Thumbnail_1280x720_${language[0]}`,
    onImageRePosition: onShareOffset
  },
  {name: 'twitter', title: 'Twitter (1200x675)', template: 'twitter', isFocus: true, url: urls.twitter, filename: `Twitter_Post_1200x675_${language[0]}`},
  {name: 'facebook', title: 'Facebook (1200x630)', template: 'facebook', url: urls.facebook, filename: `Facebook_1200x630_${language[0]}`},
  {name: 'linkedin', title: 'Linkedin (1200x627)', template: 'linkedin', url: urls.linkedin, filename: `LinkedIn_1200x627_${language[0]}`},
  {
    name: 'post',
    title: 'YouTube Community 1080x1080',
    template: 'youtubeCommunity',
    url: urls.post,
    filename: `YouTube_Community_1080x1080_${language[0]}`
  },
  {
    name: 'stories',
    title: 'Instagram Stories 1080x1920',
    template: 'instagram',
    url: urls.stories,
    filename: `Instagram_Story_1920x1080_${language[0]}`,
    className: 'story-2'
  },
  {
    name: 'countdown',
    title: 'Instagram Countdown 1080x1920',
    template: 'countdown',
    url: urls.countdown,
    filename: `Instagram_Story_1920x1080_Countdown_${language[0]}`,
    className: 'countdown'
  },
  {
    name: 'featured',
    title: 'Blog Featured 1280x720@2x',
    template: 'blog',
    url: urls.featured,
    filename: `Featured_Blog_1280x720_${language[0]}`,
    onImageRePosition: onBlogOffset
  },
  {
    name: 'share',
    title: 'Blog Social Share 1280x720@2x',
    template: 'share',
    url: urls.share,
    filename: `Social_Share_Blog_1280x720_${language[0]}`,
    onImageRePosition: onShareOffset
  },
  {
    name: 'emailphoto',
    title: 'Email Photo 650x144@2x',
    template: 'emailPhoto',
    url: urls.emailphoto,
    filename: `Email_Photo_650x144@2x_${language[0]}`,
    onImageRePosition: onShareOffset
  },
  {
    name: 'emailbanner',
    title: 'Email Banner 650x325@2x',
    template: 'emailBanner',
    url: urls.emailbanner,
    filename: `Email_Banner_650x325@2x_${language[0]}`,
    onImageRePosition: onShareOffset
  },

];

export default getSections;
