import JSZip from "jszip";
import dom2image from "dom-to-image-font-patch";
import {saveAs} from "file-saver";
import constants from "./data/constants";

export async function sendGAEvent(eventCategory,eventAction, eventLabel ) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'GAEvent',
    'eventCategory': eventCategory,
    'eventAction': eventAction,
    'eventLabel': eventLabel
  })
}

export const clearFormatting = html => {
    const regex = /style="[^\"]*"/gi
    const nostyle = html.replace(regex, "");
    const regex2 = /<((?!p|\/p|p|br|div|\/div)[^>]+)>/ig;
    const result = nostyle.replace(regex2, "");
    return result;
}


export const loadUrls = async ( variation, stage, product, urlTypes, department) => {

  try {
    if (variation === undefined
      || stage === undefined
      || department === undefined
      || product === undefined
      || urlTypes === undefined) {
      return { variationQty: 1, urls: {} };
    }

    const departmentName = capitalizeFirstLetter(department);

    let url = `${constants.KRAKEN_URL}/tiles/get_tilesets?prefix=Coverbaker/${departmentName}/${product?.value}/${stage.value}`;
    let key = `Coverbaker/${departmentName}/${product?.value}/${stage.value}/${variation}`;
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const result = await response.json();

    if (!result) {
      throw new Error('Server error, no tile urls loaded');
    } else {
      console.log('images arrived!');
    }

    let rawUrls = result.tile_sets;
    const folderQty = Object.keys(rawUrls).length;
    if(variation === 'custom') {
      key = `Coverbaker/Social/ALL/default/1`;
      url = `${constants.KRAKEN_URL}/tiles/get_tilesets?prefix=Coverbaker/Social/ALL/default`;
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const result = await response.json();
      rawUrls = result.tile_sets;
    }

    // setVariationQty(folderQty ? folderQty : 1);

    const getUrlTypes = (filteredUrls, urlTypes) => {
      return filteredUrls.reduce((acc, url) => {
        urlTypes.forEach(type => {
          if (url.toLowerCase().includes(type)) {
            if (!acc[type]) {
              acc[type] = [];
            }
            acc[type].push(url);
          }
        });
        return acc;
      }, {});
    };

    const filteredUrls = rawUrls[key];
    const newUrls = getUrlTypes(filteredUrls, urlTypes);
    // setUrls(newUrls);
    return { variationQty: folderQty ? folderQty : 1, urls: newUrls };


  } catch (err) {
    console.error(err);
  }
};

export const loadBenchmarkUrls = async ( variation, stage, product, urlTypes, department ) => {
  try {
    if (variation === undefined
      || stage === undefined
      || department === undefined
      || product === undefined
      || urlTypes === undefined) {
      return { variationQty: 1, urls: {} };
    }

    const departmentName = capitalizeFirstLetter(department);
    let url = `${constants.KRAKEN_URL}/tiles/get_tilesets?prefix=Coverbaker/Benchmark/${departmentName}`;
    let key = `Coverbaker/Benchmark/${departmentName}/${variation}`;

    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const result = await response.json();

    if (!result) {
      throw new Error('Server error, no tile urls loaded');
    } else {
      console.log('images arrived!');
    }

    let rawUrls = result.tile_sets;
    const folderQty = Object.keys(rawUrls).length;

    // setVariationQty(folderQty ? folderQty : 1);

    const getUrlTypes = (filteredUrls, urlTypes) => {


      return filteredUrls.reduce((acc, url) => {
        urlTypes.forEach(type => {
          if (url.toLowerCase().includes(type)) {
            if (!acc[type]) {
              acc[type] = [];
            }
            acc[type].push(url);
          }
        });
        return acc;
      }, {});
    };

    const filteredUrls = rawUrls[key];
    const newUrls = getUrlTypes(filteredUrls, urlTypes);
    // setUrls(newUrls);
    return { variationQty: folderQty ? folderQty : 1, urls: newUrls };


  } catch (err) {
    console.error(err);
  }
};

export const getFontFactor = (textLength) => {
  let fontFactor;

  if (textLength < 50) {
    fontFactor = 'huge';
  } else if (textLength < 80) {
    fontFactor = 'normal';
  } else {
    fontFactor = 'small';
  }

  return fontFactor;
};

export const checkProductInText = (product, headerTextState) => {
  let label = product.label.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  let regex = new RegExp(`\\b${label}\\b`);

  if(product.label === 'JetBrains Academy') {
    regex = new RegExp(`\\bAcademy\\b`);
  }
  return regex.test(headerTextState[0]);
};

export const setFocus = (isFocus, focusRef) => {
  if (isFocus && focusRef) {
    const selection = window.getSelection();
    const range = document.createRange();
    selection.removeAllRanges();
    range.selectNodeContents(focusRef.current);
    range.collapse(false);
    selection.addRange(range);
    focusRef.current.focus();
  }
}

export function capitalizeFirstLetter(string) {
  const stringWithoutSlash = string.replace('/', '');
  return stringWithoutSlash.charAt(0).toUpperCase() + stringWithoutSlash.slice(1);
}

export async function captureImages(nodes, product) {
  const zip = new JSZip();

  const prepareNodeForCapture = (node) => {

    const dropAreas = node.querySelectorAll('.dropArea:not(:has(img))');
    dropAreas.forEach(area => {
      area.setAttribute('data-original-text', area.innerHTML);
      area.innerHTML = '';
    });

    const controls = node.querySelectorAll('.controls');
    controls.forEach(control => {
      control.style.display = 'none';
    });

    return { dropAreas, controls };
  };

  const restoreNode = ({ dropAreas, controls }) => {
    dropAreas.forEach(area => {
      const originalText = area.getAttribute('data-original-text');
      if (originalText) {
        area.innerHTML = originalText;
        area.removeAttribute('data-original-text');
      }
    });

    controls.forEach(control => {
      control.style.display = '';
    });
  };

  await Promise.all(
    [...nodes].map(async (node) => {
      try {
        const originFilename = node
          .getAttribute("data-filename")
          .toLowerCase();

        const elementsToRestore = prepareNodeForCapture(node);

        await new Promise(resolve => setTimeout(resolve, 100));

        const dataUrl = await dom2image.toPng(node, {
          cacheBust: true,
          filter: (element) => {
            return !element.classList?.contains('controls') &&
              !(element.classList?.contains('dropArea') && !element.querySelector('img'));
          }
        });

        restoreNode(elementsToRestore);

        zip.file(
          `${originFilename}.png`,
          dataUrl.substring("data:image/png;base64,".length),
          { base64: true }
        );
      } catch (error) {
        console.error('Error capturing node:', error);
        throw error;
      }
    })
  );

  const result = await zip.generateAsync({type: "blob"});

  saveAs(result, product ? product.label + ".zip" : "images.zip");
}

export function checkTriggersInUrls(urls) {
  const keywords = ['whitelogo', 'blacklogo', 'black_logo_white', 'outdated', 'blacktext'];
  let foundKeywords = ['empty'];

  if (Object.keys(urls).length > 0)  {
    foundKeywords =  keywords.filter(keyword =>
      Object.values(urls).some(urlSet => urlSet.some(url => url.includes(keyword)))
    );
  }
  return foundKeywords.join(' ');
}

