import { useEffect } from "react";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import Taxonomy from "./components/taxonomy";
import { capitalizeFirstLetter } from './tools.js';



import "./App.css";

import Chevron from "./pages/chevron";
import Sharing from "./pages/sharing";

import Social from "./pages/social";
import Livestream from "./pages/livestream";
import Internal from "./pages/internal";
import Emails from "./pages/emails";
import Dark from "./pages/dark";


const components = {
  Chevron,
  Sharing,
  Social,
  Livestream,
  Internal,
  Emails,
  Dark
};

const App = () => {
  useEffect(() => {
    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if (!isChrome) {
      document.body.classList.add('not-chrome');
    }
  }, []);

  const routes = Taxonomy.channels.map(channel => {
    if (channel.visible) {
      const componentName = capitalizeFirstLetter(channel.value.replace('/', ''));
      const Component = components[componentName];
      return Component ? <Route key={channel.value} path={channel.value} component={Component} /> : null;
    }
    return null;
  });

  return (
    <BrowserRouter>
      <div spellCheck="false" className="App">
        <Route
          exact
          path="/"
          render={({ location }) => (
            <Redirect
              to={location.hash ? location.hash.substring(1) : "/social/JB"}
            />
          )}
        />
        <Route
          exact
          path="/chevron/:scale/:hash"
          render={(props) => (
            <Chevron
              hash={props.match.params.hash}
              scale={props.match.params.scale}
            />
          )}
        />
        <Route
          exact
          path="/sharing/:scale/:hash"
          render={(props) => (
            <Sharing
              hash={props.match.params.hash}
              scale={props.match.params.scale}
            />
          )}
        />
        {routes}
      </div>
    </BrowserRouter>
  );
};

export default App;
