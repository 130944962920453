import {fetch} from 'whatwg-fetch';
import C from "./data/constants";

export const load =
    (hash, callback) => { // make async
        fetch(`${C.KRAKEN_URL}/scene/load?scene_id=${hash}&prefix=baker`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        })
        .then((response) => response.json())
        .then((scene) => {
            if (!scene.page) {
                scene.page = 'blog';
            } else {

            }
            callback(scene);
        });
    }


export const save =
    async (scene) => {
      const response = await fetch(
          `${C.KRAKEN_URL}/scene/save?prefix=baker`,
          {
              method: "POST",
              body: JSON.stringify(scene),
              headers: { "Content-Type": "application/json" },
              credentials: "same-origin",
          }
      );

      //console.log(response, await response.json());
      const { scene_id: sceneId } = await response.json();

      return sceneId;
    }

export const KRAKEN_IMAGE_PATH = 'https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos_Icons';
