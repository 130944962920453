import { memo } from "react";

import {ProductChooseRoute} from "../../components/PageProductChoose";

import DataProviders from "../../data/DataProviders";

import {useProduct} from "../../hooks/product";

import Internal from "./Internal";
import {useImageBlogOffset, useImageShareOffset} from "../../hooks";



const InternalPure = memo(Internal);

const InternalAdditions = () => {
  const [product, setProduct] = useProduct();

  const setBlogOffset = useImageBlogOffset()[1];
  const setShareOffset = useImageShareOffset()[1];

  return (
    <>
    <InternalPure
      product={product}
      onProductChange={setProduct}
      onBlogOffset={setBlogOffset}
      onShareOffset={setShareOffset}
    />
      Here
    </>
  );
};

const InternalWithData = () => (
  <ProductChooseRoute>
      <DataProviders component={InternalAdditions} />
  </ProductChooseRoute>
);

export default InternalWithData;
