import { Component } from "react";
import { Container, Row, Col } from "@webteam/layout";
import "../../App.css";
import "./index.css";
import Image from "../../components/image";
import "@webteam/core-styles";
import "@webteam/typography/lib/index.css";
import "@webteam/typography/lib/font-development.css";
import {load} from "../../kraken";

class Chevron extends Component {
  state = {
    badges: [],
    chargeShoot: 0,
    header: "Your Personal Developer Ecosystem \n Survey Results Are Here",
    shootReady: "not-ready",
    scale: 1,
  };

  fetchData = (opts) => {
    load(opts.hash, (data) =>
      this.setState({
        badges: data.page.children[0].properties.badges,
      })
    );
  };

  componentDidMount() {
    document.title = "Dark Baker";
    this.fetchData({ hash: this.props.hash + ".json" });
  }

  chargeShoot = () => {
    this.setState({ chargeShoot: this.state.chargeShoot + 1 });
    if (this.state.chargeShoot + 1 === this.state.badges.length) {
      this.setState({ shootReady: "image-ready" });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.hash !== this.props.hash) {
      this.fetchData({ hash: this.props.hash + ".json" });
    }

    if (this.props.scale && prevProps.scale !== this.props.scale) {
      this.setState({ scale: this.props.scale });
    }
  }

  render() {
    const { header, badges, scale, shootReady } = this.state;

    return (
      <div id="chevrons" className="App">
        <Container>
          {
            <div
              key="1"
              id={"forShoot"}
              className={"chevron " + shootReady}
              style={{
                transform:
                  "scale(" +
                  scale +
                  ") translate(0, " +
                  163 * (scale - 1) +
                  "px)",
              }}
            >
              <Row style={{ height: "650px" }}>
                <Col
                  className={"chevron_container wt-col_align-self_center"}
                  span={12}
                >
                  <Row className={"header"}>
                    <Col span={12}>{header}</Col>
                  </Row>
                  <Row className={"wt-row_justify_center"}>
                    <Col span={12} className={"badges_qty_" + badges.length}>
                      <div className={"cont"}>
                        {badges.map((badge, index) => (
                          <Image
                            key={index}
                            class={"item"}
                            src={badge.url}
                            width={"72px"}
                            height={"72px"}
                            onload={this.chargeShoot}
                          />
                        ))}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          }
        </Container>
      </div>
    );
  }
}

export default Chevron;
