import {
  useCallback, useEffect,
  useMemo,
  useState,
} from "react";

import {load} from "../kraken";
import {useProduct} from "../hooks/product";
import {useSceneId} from "../hooks/sceneId";
import {ContextsProvider} from "../components/ContextsProvider";
import {languages} from "./constants";
import {API} from "../api";
import default_translations from "./translations";

function BlogState({component: Child}) {
  const [appliedScene, setAppliedScene] = useState(null);

  const language = useState('en');
  const [, setLanguage] = language;

  const headerTextState = useState("Name of the social");
  const [, setHeaderText] = headerTextState;

  const [product, setProduct] = useProduct();

  const [hash] = useSceneId();

  const department = useState();
  const [currentDepartment, setDepartment] = department;
  const [type, setType] = useState({currentDepartment});




  const [image, setImage] = useState(null);
  const imageBlogOffsetState = useState(null);
  const imageShareOffsetState = useState(null);
  const [, setImageShareOffset] = imageShareOffsetState;

  const dateState = useState('Date');
  const [, setDate] = dateState;

  const nameState = useState('Name');
  const [, setName] = nameState;

  const positionState = useState('Position');
  const [, setPosition] = positionState;
  const episodeState = useState('Links');
  const [, setEpisode] = episodeState;
  const secondLineState = useState('Links');
  const [, setSecondLine] = secondLineState;

  const thirdLineState = useState('Links');
  const [, setThirdLine] = thirdLineState;

  const benchmarkState = useState('');
  const [, setBenchmarkState] = benchmarkState;

  const variation = useState(0);
  const [, setVariation] = variation;


  const stage = useState();
  const [, setStage] = stage;


  const layoutNumber = useState(0);
  const [, setLayoutNumber] = layoutNumber;

  const tag = useState(0);
  const [, setTag] = tag;

  const subproductState = useState(0);
  const [, setSubproduct] = subproductState;
  const withUploadState = useState(false);
  const [, setWithUpload] = withUploadState;
  const modeState = useState(0);
  const [, setMode] = modeState;


  const [loadingState, setLoadingState] = useState('not-loading');
  const [isTranslating, setIsTranslating] = useState(false);
  const [isTranslated, setIsTranslated] = useState(false);


  const [translations, setTranslations] = useState(default_translations['social']);


  useEffect(() => {
    switch (department[0]) {
      case 'livestream':
        setTranslations(default_translations['livestream']);
        break;
      case 'emails':
        setTranslations(default_translations['emails']);
        break;
      default:
        setTranslations(default_translations['social']);
    }
  }, [department]);

  function getNameByValue(value) {
    const language = languages.find(lang => lang.value === value);
    return language ? language.name : null;
  }

  const translate_all = useMemo(() =>  async () => {
    setIsTranslating(true);

    const currentLanguage = getNameByValue(language[0]);

    let newTranslations = {};

    const translationPromises = languages.map(async (lang) => {
      if (lang.name !== currentLanguage) {
        newTranslations[lang.name] = {
          headerText: await API.translate(headerTextState[0], lang.name),
          name: await API.translate(nameState[0], lang.name),
        };
      } else {
        newTranslations[lang.name] = {
          headerText: headerTextState[0],
          name: nameState[0],
        };
      }
    });

    await Promise.all(translationPromises);

    Object.entries(newTranslations).forEach(([key, value]) => {
      const language = languages.find(lang => lang.name === key);

      if (language) {
        translations[language.value].headerText = value.headerText;
        translations[language.value].name = value.name;
      }
    });

    setTranslations(translations);
    setIsTranslating(false);
    setIsTranslated(true);
  },  [language, headerTextState, nameState, languages, translations]);;
  const applyFromScene = //useCallback(
    (scene) => {
      const lang = scene.language || 'en';
      setHeaderText(scene.headerText || "");


      if (scene.image) {
        setImage(scene.image);
      }

      if (scene.imageBlogOffset) {
        setImageBlogOffset(scene.imageBlogOffset);
      }

      if (scene.imageShareOffset) {
        setImageShareOffset(scene.imageShareOffset);
      }


      if (scene.date) {
        setDate(scene.date);
      }

      if (scene.secondLine) {
        setSecondLine(scene.secondLine);
      }

      if (scene.thirdLine) {
        setThirdLine(scene.thirdLine);
      }

      if (scene.name) {
        setName(scene.name);
      }
      if (scene.episode) {
        setEpisode(scene.episode);
      }
      if (scene.position) {
        setPosition(scene.position);
      }

      if (scene.type) {
        const optionType = product.types.find(testType => testType.value === scene.type);
        if (optionType) setType(optionType);
      }

      if (scene.variation) {
        setVariation(scene.variation);
      }

      if (scene.stage) {
        setStage(scene.stage);
      }
      if (scene.layoutNumber) {
        setLayoutNumber(scene.layoutNumber);
      }
      if (scene.tag) {
        setTag(scene.tag);
      }
      if (scene.subproduct) {
        setSubproduct(scene.subproduct);
      }
      if (scene.mode) {
        setMode(scene.mode);
      }
      if (scene.language) {
        setLanguage(scene.language);
      }
      if (scene.withUpload) {
        setWithUpload(scene.withUpload);
      }
      if (scene.department) {
        setDepartment(scene.department);
      }
      setAppliedScene(hash);
      setLoadingState('loaded');
    };
  // [language]
  //);


  useEffect(() => {
    setIsTranslated(false);
    let defaultHeaderText, defaultCTAText;
    const lang = language[0];
    switch (stage[0]?.value) {
      case 'java-annotated':
        defaultHeaderText = translations[lang].annotationText;
        break;
      case 'dotnet-annotated':
        defaultHeaderText = translations[lang].dotNetAnnotationText;
        break;
      case 'php-annotated':
        defaultHeaderText = translations[lang].phpAnnotationText;
        break;
      case 'newsletter':
        defaultHeaderText = translations[product?.value].newsletterText;
        defaultCTAText = translations[product?.value].ctaText;
        break;
      case 'easyhacks':
        defaultHeaderText = translations[lang].easyhacks;
        break;
      case 'ceoarticle':
        defaultHeaderText = translations[lang].seoarticle;
        break;
      case 'expertarticles':
        defaultHeaderText = translations[lang].expertarticles;
        break;
      case 'javaidea':
        defaultHeaderText = translations[lang].javaidea;
        break;
      case 'fasttrack':
        defaultHeaderText = translations[lang].fasttrack;
        break;
      default:
        defaultHeaderText = translations[lang].headerText;
    }

    setHeaderText(defaultHeaderText || "");
    setDate(defaultCTAText? defaultCTAText: translations[lang].date);
    setName(translations[lang].name);
    setPosition(translations[lang].position);
    setEpisode(translations[lang].episode);
    setSecondLine(translations[lang].secondLine);
    setThirdLine(translations[lang].thirdLine);
  }, [language[0], translations, stage[0]?.value]
  );

  useEffect(
    () => {
      if (hash && hash.length && appliedScene !== hash) {
        setLoadingState('loading');
        load(hash, applyFromScene);
      }
    },
    [appliedScene, hash, setLoadingState, applyFromScene]
  );

  const setImageBlogOffset = imageBlogOffsetState[1];
  const setImageShareOffsetState = imageShareOffsetState[1];


  const setImageComplex = useCallback(
    (image) => {
      setImage(image);

      if (!image) {
        setImageBlogOffset(null);
        setImageShareOffsetState(null);
      }
    },
    [setImage, setImageBlogOffset, setImageShareOffsetState]
  );

  return (
    <ContextsProvider
      Component={Child}
      ProductContext={useMemo(
        () => [product, setProduct],
        [product, setProduct]
      )}
      TypeContext={useMemo(
        () => [type, null],
        [type]
      )}
      DepartmentContext={useMemo(() => department, department)}
      VariationContext={useMemo(() => variation, variation)} // eslint-disable-line react-hooks/exhaustive-deps
      StageContext={useMemo(() => stage, stage)}
      LayoutNumberContext={useMemo(() => layoutNumber, layoutNumber)} // eslint-disable-line react-hooks/exhaustive-deps
      TagContext={useMemo(() => tag, tag)} // eslint-disable-line react-hooks/exhaustive-deps
      SubproductContext={useMemo(() => subproductState, subproductState)} // eslint-disable-line react-hooks/exhaustive-deps
      ModeContext={useMemo(() => modeState, modeState)} // eslint-disable-line react-hooks/exhaustive-deps
      WithUploadContext={useMemo(() => withUploadState, withUploadState)} // eslint-disable-line react-hooks/exhaustive-deps
      HeaderTextContext={headerTextState}
      NameContext={useMemo(() => nameState, nameState)} // eslint-disable-line react-hooks/exhaustive-deps
      PositionContext={useMemo(() => positionState, positionState)} // eslint-disable-line react-hooks/exhaustive-deps
      EpisodeContext={useMemo(() => episodeState, episodeState)} // eslint-disable-line react-hooks/exhaustive-deps
      SecondLineContext={useMemo(() => secondLineState, secondLineState)} // eslint-disable-line react-hooks/exhaustive-deps
      ThirdLineContext={useMemo(() => thirdLineState, thirdLineState)} // eslint-disable-line react-hooks/exhaustive-deps
      BenchmarkContext={useMemo(() => benchmarkState, benchmarkState)} // eslint-disable-line react-hooks/exhaustive-deps
      ImageContext={useMemo(
        () => [image, setImageComplex],
        [image, setImageComplex]
      )}
      ImageBlogOffsetContext={useMemo(
        () => imageBlogOffsetState,
        imageBlogOffsetState // eslint-disable-line react-hooks/exhaustive-deps
      )}
      ImageShareOffsetContext={useMemo(
        () => imageShareOffsetState,
        imageShareOffsetState // eslint-disable-line react-hooks/exhaustive-deps
      )}
      LanguageContext={useMemo(() => language, [language])} // eslint-disable-line react-hooks/exhaustive-deps
      TranslateContext={useMemo(() => [translate_all], [translate_all])}
      TranslatingStateContext={useMemo(() => [isTranslating, setIsTranslating, isTranslated, setIsTranslated], [isTranslating, setIsTranslating, isTranslated, setIsTranslated])}
      LoadingStateContext={useMemo(
        () => [loadingState, setLoadingState],
        [loadingState, setLoadingState]
      )}
      DateContext={useMemo(
        () => dateState,
        dateState // eslint-disable-line react-hooks/exhaustive-deps
      )}
    />
  );
}

export default BlogState;
