import { useCallback, useEffect, useMemo, useState } from "react";
import cx from "classnames";
import ImageUploading from "react-images-uploading";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import debounce from "lodash.debounce";
import { TrashIcon, UndoIcon } from "@webteam/icons";

import styles from "./style.module.css";

const getImageOrientation = (image) =>
  new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      resolve(img.width > img.height ? "horiz" : "vert");
    };

    img.onerror = reject;

    img.src = image.data_url;
  });

export const ImageUpload = ({
  className,
  image,
  setImage,
  onPositionChange,
  minScale,
  initialScale
}) => {
  const [loading, setLoading] = useState(false);
  const [orientation, setOrientation] = useState("horiz");

  const onChange = useCallback(
    (imageList) => {
      setImage(imageList[0] || null);
    },
    [setImage]
  );

  useEffect(() => {
    let isSubscribed = true;

    if (image) {
      setLoading(true);

      getImageOrientation(image)
        .then((value) => isSubscribed && setOrientation(value))
        .finally(() => isSubscribed && setLoading(false));
    }

    return () => {
      isSubscribed = false;
    };
  }, [image]);

  const handlePositionChange = useMemo(
    () =>
      debounce((context) => {
        const state = context && context.state;

        if (state && onPositionChange)
          onPositionChange({
            positionX: context.state.positionX,
            positionY: context.state.positionY,
            scale: context.state.scale,
          });
      }, 150),
    [onPositionChange]
  );



  return (
    <ImageUploading
      dataURLKey="data_url"
      value={useMemo(() => [image], [image])}
      onChange={onChange}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageRemove,
        isDragging,
        dragProps,
      }) => {
        const image = imageList[0];
        const isUploaded = !loading && image;
        const classSizes = {
          [orientation === "horiz" ? "height" : "width"]: "100%",
        };

        return (
          <div
            className={cx(styles.preview, className, {
              [styles.previewUploaded]: isUploaded,
            })}
            {...dragProps}
          >
            <button onClick={onImageUpload} className={[styles.dropButton, 'dropArea'].join(' ')}>
              Click or Drop {<br/>} Image here
            </button>
            {isUploaded && (
              <TransformWrapper
                centerZoomedOut={false}
                limitToBounds={false}
                centerOnInit={true}
                minScale={minScale || 1}
                initialScale={initialScale || 1}
                wheel={{ step: 0.03 }}
                ref={handlePositionChange}
              >
                {({ zoomIn, zoomOut, resetTransform }) => (
                  <>
                    <div className={`${styles.controls} controls`}>
                      <button
                        className={styles.roundButton}
                        onClick={() => zoomIn()}
                      >
                        +
                      </button>
                      <button
                        className={styles.roundButton}
                        onClick={() => zoomOut()}
                      >
                        −
                      </button>
                      <button
                        className={styles.roundButton}
                        onClick={() => resetTransform()}
                      >
                        <UndoIcon style={{color: "white"}}/>
                      </button>
                      <button
                        className={styles.roundButton}
                        onClick={onImageRemoveAll}
                      >
                        <TrashIcon style={{color: "white"}}/>
                      </button>
                    </div>
                    <TransformComponent
                      wrapperClass={styles.wrapper}
                      contentStyle={classSizes}
                    >
                      <img src={image.data_url} {...classSizes} />
                    </TransformComponent>
                  </>
                )}
              </TransformWrapper>
            )}
          </div>
        );
      }}
    </ImageUploading>
  );
};
