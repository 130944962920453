import {useCallback} from "react";
import Toolbox from "../Toolbox";
import {useProduct} from "../../hooks/product";

export const ProductChooseLayout = ({
  children,
  loading,
  toolbox,
}) => {
  const [ product, setProduct ] = useProduct();
  const { onProductChange, ...toolboxProps } = toolbox || {};

  const handleProductChange = useCallback(
    productId => {
      if (onProductChange) onProductChange(productId);
      setProduct(productId);
    },
    [ onProductChange, setProduct ]
  );

  return <>
    <Toolbox
      key={product.value}
      product={product}
      {...toolboxProps}
      onProductChange={handleProductChange}
    />
    <div id="container">
      <span className="kraken_loading_status" style={{ display: "none" }}>{loading}</span>
      {children}
    </div>
  </>;
}
