import {useCallback, useState, createRef, useEffect, useMemo, useContext} from "react";
import getSections from '../../data/sections';
import Toggle from "@webteam/toggle";

import {captureImages, loadUrls, getFontFactor, checkProductInText, loadBenchmarkUrls, checkTriggersInUrls} from "../../tools";

import {
  useLoadingState,
  useVariation,
  useDepartment,
  useLanguage,
  useTranslate,
  useLayoutNumber,
  useTranslatingStateContext,
  HeaderTextContext, useStage,
  useBenchmark
} from "../../hooks";

import {useScreenshoter} from "../../hooks/screenshoter";

import {ProductChooseLayout} from "../../components/PageProductChoose/Layout";
import Cover from "../../components/covers";


import styles from "./social.module.css";
import page from "../page.module.css";
import "./style.css";
// import "./style.scss";
import {useProducts} from "../../hooks/product";

const urlTypes = [
  'featured',
  'share',
  'twitter',
  'facebook',
  'linkedin',
  'post',
  'stories',
];


export const Social = ({product, onProductChange, onBlogOffset, onShareOffset}) => {
  const [exporting, setExporting] = useState(false);
  const [loading,] = useLoadingState();
  const [isTranslating, setIsTranslating, isTranslated, setIsTranslated] = useTranslatingStateContext();

  const focusRef = createRef();
  const [variationQty, setVariationQty] = useState(1);
  const [templateQty, setTemplateQty] = useState(7);
  const [tagQty, setTagQty] = useState(6);
  const department = useDepartment();


  const products = useProducts();

  const save = useCallback(() => {
    setExporting(true);

    captureImages(document.getElementsByClassName("capture-image"), product)
      .catch((e) => console.log(e))
      .finally(() => setExporting(false));
  }, [setExporting, product]);

  const share = useScreenshoter();

  const isSaving = (loading === 'loading');

  const isProgress = exporting;

  const [variation, setVariation] = useVariation();
  const [benchmark] = useBenchmark();
  const [layoutNumber, ] = useLayoutNumber();
  const [stage, ] = useStage();
  const language = useLanguage();
  const [translate_all] = useTranslate();

  const [urls, setUrls] = useState({});
  const [isVPN, ] = useState(true);

  const [isProductInText, setIsProductInText] = useState(false);
  const [mainFontFactor, setMainFontFactor] = useState("x1");
  const headerTextState = useContext(HeaderTextContext);
  const [classes, setClasses] = useState('');


  useEffect(() => {
    const isProductInText = checkProductInText(product, headerTextState);
    setIsProductInText(isProductInText);
  }, [headerTextState[0], product]);


  useEffect(() => {
    const textLength = headerTextState[0].length;
    const fontFactor = getFontFactor(textLength);
    setMainFontFactor(fontFactor);
  }, [headerTextState[0], language]);



  useEffect(() => {
    let isCancelled = false;
    try {
      const fetchUrls = async () => {
        let result = {};
        if(benchmark) {
          result = await loadBenchmarkUrls(variation, stage, product, urlTypes, department[0]);
        } else {
          result = await loadUrls(variation, stage, product, urlTypes, department[0]);
        }
        setClasses(checkTriggersInUrls(result?.urls || {}));

        if (!isCancelled && result) {
          const { variationQty = 1, urls = {} } = result;
          setVariationQty(variationQty);
          setUrls(urls);
        }
      };
      fetchUrls().catch(error => console.error('Error in fetchUrls:', error));

      return () => {
        isCancelled = true;
      };

    } catch (error) {
      console.error(error);
    }
  }, [product, variation, stage, urlTypes, department]);



  const sections = getSections(urls, language, onShareOffset, onBlogOffset);
  const filteredSections = sections.filter(section => urlTypes.includes(section.name));

  const [toggleStates, setToggleStates] = useState(filteredSections.map(() => true));

  const handleToggleChange = (index) => () => {
    const newToggleStates = [...toggleStates];
    newToggleStates[index] = !newToggleStates[index];
    setToggleStates(newToggleStates);
  };

  return (
    <div id="covers"
         className={`App social ${classes} ${benchmark?"benchmark":''} ${product.value} ${product?.family} language-${language[0]} template-${layoutNumber} ${isTranslated ? 'isTranslated' : ''} ${isProductInText ? 'isProductInText' : ''} ${mainFontFactor} stage-${stage?.value}`}>
      <ProductChooseLayout
        toolbox={{
          onProductChange,
          setVariation,
          variation,
          variationQty,
          templateQty,
          tagQty,
          language: language,
          loading: isProgress,
          translating: isTranslating,
          saving: isSaving,
          save,
          share,
          products,
          isVPN,
          translate_all
        }}>
        {filteredSections.map((section, index) => (

 <section key={index} className={`${page.section} section-${section.template} ${toggleStates[index] ? "" : "no-exportable"}`}>
      <h2 className={page.title}>
       {section.title}
      </h2>
      <Cover
        // ref={section.isFocus ? focusRef : null}
        exportable={toggleStates[index]}
        template={section.template}
        onImageRePosition={section.onImageRePosition}
        style={{backgroundImage: `url(${section.url ? section.url[0] : 'default_bg.png'})`}}
        exportFilename={section.filename}
        className={section.className}
      />
   <Toggle
     className="visibility-toggle exportable-toggle"
     size="xs"
     theme="dark"
     labelPlacement={"left"}
     name="exportable"
     onChange={handleToggleChange(index)}
     checked={toggleStates[index]}
   >Export </Toggle>
          </section>
        ))}
      </ProductChooseLayout>
    </div>
  );
};

export default Social;
