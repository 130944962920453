const Taxonomy = {
  products: [


    {
      value: "IJ",
      label: "IntelliJ IDEA",
      family: "IDE",
      types: [
        {
          label: "General",
          value: "general",
        },
        { label: "Java Annotated",
          value: "java-annotated"
        },
        {
          label: "Release",
          value: "release",
          variations: 14
        },
        {
          label: "EAP",
          value: "eap"
        },

        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "News",
        //   value: "news",
        // },
        // {
        //   label: "Tips&Tricks",
        //   value: "tips-tricks",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "DN",
      label: ".NET",
      types: [
        {
          label: "General",
          value: "general"
        },
        {
          label: "Newsletter",
          value: "dotnet-annotated"
        },

      ],
    },
    {
      value: "IH",
      label: "Innovation Hub",
      family: "initiative",
      types: [
        {
          label: "Workshop",
          value: "workshop"
        },
        {
          label: "Hackathon",
          value: "hackathon"
        },
        {
          label: "Webinar",
          value: "webinar"
        },
        {
          label: "Jam Event",
          value: "jamevent"
        }

      ],
    },
    {
      value: "AMP",
      label: "Amper",
      family: "plugin",
      types: [
        {
          label: "General",
          value: "general"
        },
        {
          label: "Release",
          value: "release",
          variations: 3
        },

      ],
    },
    {
      value: "AQ",
      label: "Aqua",
      family: "IDE",
      types: [
        {
          label: "General",
          value: "general"
        },

        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }

      ],
    },
    {
      value: "CC",
      label: "CodeCanvas",
      types: [
        {
          label: "General",
          value: "general"
        }
      ],
    },
    {
      value: "CL",
      label: "CLion",
      family: "IDE",
      types: [

        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "EAP",
        //   value: "eap"
        // },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 7,
        // },
        //
        // {
        //   label: "News",
        // },
        // {
        //   label: "Tips&Tricks",
        //   value: "tips-tricks",
        // },
        // {
        //   label: "Events",
        //   value: "events",
        // },
        // {
        //   label: "Guest Post",
        //   value: "guest-post",
        // }
      ],
    },
    {
      value: "CLN",
      label: "CLion Nova",
      types: [

        {
          label: "General",
          value: "general"
        },
        {
          label: "Release",
          value: "release",
        },
        {
          label: "Bug-fix",
          value: "bug-fix",
        },
        {
          label: "Roadmap",
          value: "roadmap-custom",
          variations: 2
        }
      ],
    },
    {
      value: "CPPT",
      label: "C++ Tools",
      types: [


        {
          label: "News",
          value: "news",
        },
        {
          label: "Tips&Tricks",
          value: "tips-tricks",
        },
        {
          label: "Events",
          value: "events",
        },
        {
          label: "Interviews",
          value: "interviews"
        },
        {
          label: "Roadmap",
          value: "roadmap-custom",
          variations: 2
        }
      ],
    },
    {
      value: "CWM",
      label: "Code With Me",
      types: [
        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "EAP",
        //   value: "eap",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "DG",
      label: "DataGrip",
      family: "IDE",
      types: [
        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "EAP",
        //   value: "eap"
        // },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "News",
        //   value: "news",
        //   variations: 4
        // },
        // {
        //   label: "Features",
        //   value: "features",
        //   variations: 4
        // },
        // {
        //   label: "Tips&Tricks",
        //   value: "tips-tricks",
        //   variations: 4
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "DC",
      label: "dotCover",
      types: [
        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "EAP",
        //   value: "eap"
        // },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "How-Tos",
        //   value: "how-tos",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "DL",
      label: "Datalore",
      types: [
        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "EAP",
        //   value: "eap"
        // },
        // {
        //   label: "Release",
        //   value: "release"
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "Blue",
        //   value: "blue",
        //   variations: 4,
        // },
        // {
        //   label: "Yellow",
        //   value: "yellow",
        //   variations: 4,
        // },
        // {
        //   label: "Green",
        //   value: "green",
        //   variations: 4,
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "DM",
      label: "dotMemory",
      types: [
        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "EAP",
        //   value: "eap"
        // },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "How-Tos",
        //   value: "how-tos",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "DP",
      label: "dotPeek",
      types: [
        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "EAP",
        //   value: "eap"
        // },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "How-Tos",
        //   value: "how-tos",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "DS",
      label: "DataSpell",
      family: "IDE",
      types: [
        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "EAP",
        //   value: "eap"
        // },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "DT",
      label: "dotTrace",
      types: [
        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "EAP",
        //   value: "eap"
        // },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "How-Tos",
        //   value: "how-tos",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "EDU",
      label: "JetBrains Academy",
      types: [
        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "News",
        //   value: "news-edu",
        //   variations: 3,
        // },
        // {
        //   label: "Interviews",
        //   value: "interviews",
        //   variations: 2,
        // },
        // {
        //   label: "JB Academy",
        //   value: "jb-academy",
        //   variations: 2,
        // },
        // {
        //   label: "Edu IDEs",
        //   value: "edu-ides",
        //   variations: 2
        // },
        // {
        //   label: "Learning Courses",
        //   value: "courses",
        // },
        // {
        //   label: "Tools for Teaching",
        //   value: "tool",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "FL",
      label: "Fleet",
      types: [
        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "EAP",
        //   value: "eap"
        // },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "How-Tos",
        //   value: "how-tos",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "GO",
      label: "GoLand",
      family: "IDE",
      types: [

        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "EAP",
        //   value: "eap"
        // },
        // {
        //   label: "Beta",
        //   value: "beta"
        // },
        // {
        //   label: "RC",
        //   value: "rc"
        // },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 3,
        // },
        // {
        //   label: "News",
        //   value: "news-text",
        // },
        // {
        //   label: "Features",
        //   value: "features",
        // },
        // {
        //   label: "Tutorials",
        //   value: "tutorials",
        // }
      ],
    },
    {
      value: "GZ",
      label: "Grazie",
      types: [

        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Update",
        //   value: "update",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 3,
        // },
        // {
        //   label: "Tutorials",
        //   value: "tutorials",
        // },
        // {
        //   label: "How-Tos",
        //   value: "how-tos ",
        // },
        // {
        //   label: "Guest Post",
        //   value: "guest-post",
        // },
      ],
    },
    {
      value: "HB",
      label: "Hub",
      types: [
        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "News",
        //   value: "news",
        // },
        // {
        //   label: "Features",
        //   value: "features",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "JB",
      label: "JetBrains",
      types: [
        {
          label: "General",
          value: "general"
        },
        {
          label: "Newsletter",
          value: "newsletter",
        },
        //
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2,
        // },
        // {
        //   label: "Tips&Tricks",
        //   value: "tips-tricks",
        // },
        // {
        //   label: "Features",
        //   value: "features",
        // },
        // {
        //   label: "Tutorials",
        //   value: "tutorials",
        // },
        // {
        //   label: "How-Tos",
        //   value: "how-tos ",
        // },
        // {
        //   label: "Events",
        //   value: "events",
        // },
        // {
        //   label: "Interviews",
        //   value: "interviews",
        // },
        // {
        //   label: "Guest Post",
        //   value: "guest-post",
        // },
        // {
        //   label: "Security",
        //   value: "security",
        //   variations: 3,
        // }

      ],
    },
    {
      value: "JBAI",
      label: "JetBrains AI",
      types: [
        {
          label: "General",
          value: "general",
          variations: 8
        },
        // {
        //   label: "Release",
        //   value: "release",
        //   variations: 4
        // },
        // {
        //   label: "How-Tos",
        //   value: "how-tos",
        //   variations: 5,
        //   subproducts: 12,
        //   withupload: true
        // },
        // {
        //   label: "In Product",
        //   value: "roadmap-custom",
        //   variations: 2,
        //   subproducts: 12,
        // }
        // ,
        // {
        //   label: "People Talk",
        //   value: "guest-post"
        // }
      ],
    },
    {
      value: "KT",
      label: "Kotlin",
      types: [

        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "News",
        //   value: "news",
        // },
        // {
        //   label: "Tips&Tricks",
        //   value: "tips-tricks",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "MP",
      label: "Marketplace",
      types: [
        {
          label: "General",
          value: "general"
        },
        {
          label: "Newsletter",
          value: "newsletter",
        },
        // {
        //   label: "Busy Plugin Developers",
        //   value: "news-edu",
        // },
        // {
        //   label: "Interviews",
        //   value: "interviews",
        // },
        // {
        //   label: "Big Data Tools",
        //   value: "bigdatatools",
        //   variations: 3,
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 3
        // }
      ],
    },
    {
      value: "MPS",
      label: "MPS",
      types: [

        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "EAP",
        //   value: "eap"
        // },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "News"
        // },
        // {
        //   label: "Features",
        //   value: "features",
        // },
        // {
        //   label: "Events",
        //   value: "events",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "PC",
      label: "PyCharm",
      family: "IDE",
      types: [
        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "EAP",
        //   value: "eap"
        // },
        //
        // {
        //   label: "RC",
        //   value: "rc"
        // },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "News",
        //   value: "news"
        // },
        // {
        //   label: "Tips&Tricks",
        //   value: "tips-tricks",
        // },
        // {
        //   label: "Features",
        //   value: "features",
        // },
        // {
        //   label: "Tutorials",
        //   value: "tutorials",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "PS",
      label: "PhpStorm",
      family: "IDE",
      types: [
        {
          label: "General",
          value: "general"
        },
        {
          label: "PHP Annotated",
          value: "php-annotated"
        },
        // {
        //   label: "EAP",
        //   value: "eap"
        // },
        // {
        //   label: "Beta",
        //   value: "beta"
        // },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "News",
        //   value: "news",
        // },
        // {
        //   label: "Features",
        //   value: "features",
        // },
        // {
        //   label: "Tutorials",
        //   value: "tutorials",
        // },
        // {
        //   label: "Events",
        //   value: "news-text",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "QD",
      label: "Qodana",
      types: [
        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "Release",
        //   value: "release",
        //   variations: 10
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "News",
        //   value: "news-text",
        // },
        // {
        //   label: "Features",
        //   value: "features",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "RD",
      label: "Rider",
      family: "IDE",
      types: [
        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "EAP",
        //   value: "eap"
        // },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "Bug-fix Prev.",
        //   value: "bug-fix-prev",
        // },
        // {
        //   label: "How-Tos",
        //   value: "how-tos",
        // },
        // {
        //   label: "GameDev",
        //   value: "gamedev",
        //   variations: 2
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 5
        // }
      ],
    },
    {
      value: "RF",
      label: "RiderFlow",
      types: [
        {
          label: "General",
          value: "general"
        },
        {
          label: "Release",
          value: "release",
        },
        {
          label: "EAP",
          value: "eap"
        },
        {
          label: "Bug-fix",
          value: "bug-fix",
        },
        {
          label: "Tips&Tricks",
          value: "tips-tricks",
        },
        {
          label: "Roadmap",
          value: "roadmap-custom",
          variations: 2
        }
      ],
    },
    {
      value: "RM",
      label: "RubyMine",
      family: "IDE",
      types: [
        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "EAP",
        //   value: "eap"
        // },
        // {
        //   label: "Beta",
        //   value: "beta"
        // },
        // {
        //   label: "RC",
        //   value: "rc"
        // },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "News"
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "RR",
      label: "RustRover",
      family: "IDE",
      types: [
        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "EAP",
        //   value: "eap"
        // },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "News",
        //   value: "news",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "RS",
      label: "ReSharper",
      family: "IDE",
      types: [
        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "EAP",
        //   value: "eap"
        // },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "Bug-fix Prev.",
        //   value: "bug-fix-prev",
        // },
        // {
        //   label: "How-Tos",
        //   value: "how-tos",
        //   variations: 2
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 5
        // }
      ],
    },
    {
      value: "RScpp",
      label: "ReSharper C++",
      family: "IDE",
      types: [

        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "EAP",
        //   value: "eap"
        // },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "News",
        //   value: "news",
        // },
        // {
        //   label: "Tips&Tricks",
        //   value: "tips-tricks",
        //   variations: 3,
        // },
        // {
        //   label: "How-Tos",
        //   value: "how-tos",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    // {
    //   value: "SPC",
    //   label: "SpaceCode",
    //   types: [
    //     {
    //       label: "General",
    //       value: "general"
    //     }
    //   ],
    // },
    {
      value: "TB",
      label: "Toolbox App",
      types: [
        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "Tips&Tricks",
        //   value: "tips-tricks",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "TC",
      label: "TeamCity",
      types: [
        {
          label: "General",
          value: "general"
        },
        {
          label: "Newsletter",
          value: "newsletter",
        },
        // {
        //   label: "Release",
        //   value: "release",
        //   variations: 7,
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        //   variations: 3,
        // },
        // {
        //   label: "News"
        // },
        // {
        //   label: "Features",
        //   value: "features",
        // },
        // {
        //   label: "How-Tos",
        //   value: "how-tos",
        // },
        // {
        //   label: "Guest Post",
        //   value: "guest-post",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "WS",
      label: "WebStorm",
      family: "IDE",
      types: [
        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "EAP",
        //   value: "eap"
        // },
        // {
        //   label: "Beta",
        //   value: "beta"
        // },
        // {
        //   label: "RC",
        //   value: "rc"
        // },
        //
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2,
        // },
        // {
        //   label: "Tips&Tricks",
        //   value: "tips-tricks",
        // },
        // {
        //   label: "Features",
        //   value: "features",
        // },
        // {
        //   label: "Interviews",
        //   value: "interviews",
        //   variations: 4,
        // },
        // {
        //   label: "All Things Web",
        //   value: "all-things",
        //   variations: 4,
        // },
        // {
        //   label: "JS Roundup",
        //   value: "roundup",
        // },
      ],
    },
    {
      value: "WRS",
      label: "Writerside",
      family: "IDE",
      types: [
        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "EAP",
        //   value: "eap"
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "Tips&Tricks",
        //   value: "tips-tricks",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "YT",
      label: "YouTrack",
      types: [
        {
          label: "General",
          value: "general"
        },
        // {
        //   label: "Release",
        //   value: "release",
        // },
        // {
        //   label: "Bug-fix",
        //   value: "bug-fix",
        // },
        // {
        //   label: "News",
        //   value: "news-text",
        // },
        // {
        //   label: "Features",
        //   value: "features",
        // },
        // {
        //   label: "Events",
        //   value: "events",
        // },
        // {
        //   label: "Best Practices",
        //   value: "best-practices",
        // },
        // {
        //   label: "Roadmap",
        //   value: "roadmap-custom",
        //   variations: 2
        // }
      ],
    },
    {
      value: "IS",
      label: "IDE Services",
      types: [
        {
          label: "General",
          value: "general"
        }
      ],
    },
    {
      label: "Startup Program",
      value: "ST",
      types: [
        {
          label: "General",
          value: "general"
        }

      ],
    },

    {
      label: "AI Assistant",
      value: "AS",
      types: [
        {
          label: "General",
          value: "general"
        }

      ],
    },

    {
      label: "Gateway",
      value: "GW",
      types: [
        {
          label: "General",
          value: "general"
        }

      ],
    },

    {
      label: "Compose Multiplatform",
      value: "CMP",
      types: [
        {
          label: "General",
          value: "general"
        }

      ],
    },

    {
      label: "JetBrains IDEs",
      value: "JBIDEs",
      types: [
        {
          label: "General",
          value: "general"
        }

      ],
    },
    {
      label: "Ktor",
      value: "KTR",
      types: [
        {
          label: "General",
          value: "general"
        }

      ],
    },
    {
      label: "Game Dev",
      value: "GDV",
      family: "standalone",
      // family: "nonproduct",
      types: [
        {
          label: "General",
          value: "general"
        }

      ],
    }
  ],
  channels: [
    {
      label: "Social Media & Blog",
      value: "/social",
      visible: true,
      products: ["JB", "GDV", "JBIDEs", "CC", "AMP", "DN", "IS", "EDU", "IJ",  "JBAI", "ST", "AS", "AQ", "CL", "CWM", "DG", "DL", "DC", "DM", "DS", "DT", "GZ", "HB", "MP", "MPS", "KT", "KTR", "GW", "DP", "FL", "GO", "PC", "PS", "QD", "RM", "RR", "RS", "RD", "TC", "TB", "RScpp", "WS", "CMP", "WRS", "YT"]
    },
    {
      label: "Livestream",
      value: "/livestream",
      visible: true,
      products: ["AQ", "CL", "DG", "DS", "DL", "EDU", "GO", "IJ", "PC", "PS", "RD", "RM", "RR", "RS", "WS"]
    },
    {
      label: "Email",
      value: "/emails",
      visible: true,
      products: ["AMP", "GDV", "AQ", "CC", "AS", "CL", "CMP", "CWM", "DC", "DG", "DL", "DM", "DN", "DP", "DS", "DT", "EDU", "FL", "GO", "GW", "GZ", "HB", "IJ", "IS", "JB", "JBAI", "JBIDEs", "KT", "KTR", "MP", "MPS", "PC", "PS", "QD", "RD", "RM", "RR", "RS", "RScpp",  "ST", "TB", "TC", "WRS", "WS", "YT"]
    },
    {
      label: "Internal",
      value: "/internal",
      visible: true,
      products: ["IH"]
    },
    {
      label: "Dark",
      value: "/dark",
      visible: true,
      default: "CMP",
      products: ["CMP"]
    }
  ]
};

export default Taxonomy;
