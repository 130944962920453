import { memo } from "react";

import {ProductChooseRoute} from "../../components/PageProductChoose";

import DataProviders from "../../data/DataProviders";

import {useProduct} from "../../hooks/product";

import Emails from "./Emails";
import {useImageBlogOffset, useImageShareOffset} from "../../hooks";



const EmailsPure = memo(Emails);

const EmailsAdditions = () => {
  const [product, setProduct] = useProduct();

  const setBlogOffset = useImageBlogOffset()[1];
  const setShareOffset = useImageShareOffset()[1];

  return (
    <>
    <EmailsPure
      product={product}
      onProductChange={setProduct}
      onBlogOffset={setBlogOffset}
      onShareOffset={setShareOffset}
    />
      Here
    </>
  );
};

const EmailsWithData = () => (
  <ProductChooseRoute>
      <DataProviders component={EmailsAdditions} />
  </ProductChooseRoute>
);

export default EmailsWithData;
