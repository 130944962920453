import {createContext, useCallback, useContext} from "react";
import {save} from "../kraken";
import {useSceneId} from "./sceneId";

export const ScreenshoterContext = createContext(null);

export const useScreenshoter = () => useContext(ScreenshoterContext);

export const KrakenScreenshoter = ({ getParams, children }) => {
  const [, setHash] = useSceneId();
  const currentUrl = window.location.href;
  const shoot = useCallback(
    async () => {
      const sceneId = await save(getParams());
      setHash(sceneId);
      navigator.clipboard.writeText(`${currentUrl}/${sceneId}`);
      console.log('saving', sceneId);
    },
    [getParams, setHash],
  );

  return (
    <ScreenshoterContext.Provider value={shoot}>
      {children}
    </ScreenshoterContext.Provider>
  );
}
